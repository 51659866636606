import { Pipe, PipeTransform } from '@angular/core';
import { AccountActionType } from '@shared/service-proxies/service-proxies';

@Pipe({
  name: 'leadActivityAction',
})
export class LeadActivityActionPipe implements PipeTransform {
  private readonly actionMap: Record<AccountActionType, string> = {
    [AccountActionType._0]: 'connection sent',
    [AccountActionType._1]: 'accepted connection',
    [AccountActionType._2]: 'declined connection',
    [AccountActionType._3]: 'message sent',
    [AccountActionType._4]: 'message sent',
    [AccountActionType._5]: 'replied',
    [AccountActionType._6]: 'inmail sent',
    [AccountActionType._7]: 'inmail sent',
    [AccountActionType._8]: 'replied on inmail',
    [AccountActionType._9]: 'followed',
    [AccountActionType._10]: 'viewed profile',
    [AccountActionType._11]: 'liked post',
    [AccountActionType._12]: 'searched',
    [AccountActionType._13]: 'checked if connection',
  };

  transform(action: AccountActionType, firstLetterUppercase?: boolean): string {
    const text = this.actionMap[action] || '';

    if (firstLetterUppercase && text.length > 0) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }

    return text;
  }
}
