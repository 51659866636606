<div
  class="w-full flex flex-col text-center px-10 mx-auto"
  *ngIf="isSingleLoginInProgress$ | async; else notInProgress">
  <app-connect-progress></app-connect-progress>
</div>
<ng-template #notInProgress>
  <div class="w-full">
    <div class="flex">
      <!-- No account challenge -->
      <div
        class="w-full sm:px-0 flex flex-col gap-y-6"
        *ngIf="
          (selectedAccountAuth$ | async)?.linkedInAccount?.accountState != LinkedInAccountState._3
        ">
        <button
          *ngIf="(screenDisplayedInLogin | async) != 'login-type-selection'"
          class="hover:text-primary self-start items-center flex"
          (click)="navigateBackThroughLogin()">
          <mat-icon svgIcon="heroicons_solid:header-arrow-left" class="icon-size-4"></mat-icon>
          <span class="ml-2">Back</span>
        </button>

        <div *ngIf="(screenDisplayedInLogin | async) == 'login-type-selection'">
          <app-account-connection-method-selection
            (screenToBeDisplayed)="
              changeDisplayedScreen($event)
            "></app-account-connection-method-selection>
        </div>

        <div *ngIf="(screenDisplayedInLogin | async) == '2fa-prompt'">
          <app-enable-infinite-login-prompt
            (screenToBeDisplayed)="changeDisplayedScreen($event)"
            (userHas2Fa)="changeUserHas2fa($event)"></app-enable-infinite-login-prompt>
        </div>

        <div *ngIf="(screenDisplayedInLogin | async) == '2fa-confirmation'">
          <app-2fa-confirmation
            (screenToBeDisplayed)="changeDisplayedScreen($event)"></app-2fa-confirmation>
        </div>

        <div *ngIf="(screenDisplayedInLogin | async) == '2fa-tutorial'">
          <app-2fa-tutorial
            (screenToBeDisplayed)="changeDisplayedScreen($event)"></app-2fa-tutorial>
        </div>

        <div *ngIf="(screenDisplayedInLogin | async) == '2fa-login'">
          <app-manual-account-2fa-setup
            [username]="(connectAccountInput$ | async)?.username"
            [password]="(connectAccountInput$ | async)?.password"
            [secretKey]="(connectAccountInput$ | async)?.twoFactorAuthenticationSecretToken"
            [inboxScrapeConfiguration]="(connectAccountInput$ | async)?.inboxScrapeConfiguration"
            [inboxScrapeConfigurationLabel]="inboxScrapeConfigurationLabel"
            [inboxConfigurations]="inboxConfigurations"
            [isDisabled]="isSingleLoginInProgress$ | async"
            [isUsingCustomProxy]="_customProxyService.isUsingCustomProxy"
            (usernameChange)="usernameInputChange($event)"
            (passwordChange)="passwordInputChange($event)"
            (secretKeyChange)="twoFactorAuthenticationSecretKeyChange($event)"
            (inboxConfigurationChange)="selectedInboxOptionChange($event)"
            (countryCodeChange)="countryCodeChange($event)"
            (connectAccount)="connectAccount()"
            (toggleProxy)="toggleAddProxy()"
            [isUsingCustomProxyMandatory]="isCustomProxyRequired"
            [isProxyValidated]="
              _customProxyService.isVerificationStatus == 'success'
            "></app-manual-account-2fa-setup>
        </div>

        <ng-container *ngIf="(screenDisplayedInLogin | async) == 'manual-login'">
          <app-connect-account-manual
            [username]="(connectAccountInput$ | async)?.username"
            [password]="(connectAccountInput$ | async)?.password"
            [inboxScrapeConfiguration]="(connectAccountInput$ | async)?.inboxScrapeConfiguration"
            [inboxScrapeConfigurationLabel]="inboxScrapeConfigurationLabel"
            [inboxConfigurations]="inboxConfigurations"
            [isDisabled]="isSingleLoginInProgress$ | async"
            [isUsingCustomProxy]="_customProxyService.isUsingCustomProxy"
            [companyName]="companyName"
            (usernameChange)="usernameInputChange($event)"
            (passwordChange)="passwordInputChange($event)"
            (inboxConfigurationChange)="selectedInboxOptionChange($event)"
            (connectAccount)="connectAccount()"
            (toggleProxy)="toggleAddProxy()"
            (countryCodeChange)="countryCodeChange($event)"
            [isUsingCustomProxyMandatory]="isCustomProxyRequired"
            [isProxyValidated]="
              _customProxyService.isVerificationStatus == 'success'
            "></app-connect-account-manual>
        </ng-container>

        <!-- Redundancy check for extension here -->
        <ng-container
          *ngIf="(screenDisplayedInLogin | async) == 'extension-login' && !isWhiteLabel">
          <connect-account-extension
            (toggleProxy)="toggleAddProxy()"
            [isUsingCustomProxyMandatory]="isCustomProxyRequired"
            [isUsingCustomProxy]="
              _customProxyService.isUsingCustomProxy
            "></connect-account-extension>
        </ng-container>
      </div>

      <!-- Single account pin verification -->
      <ng-container *ngIf="selectedAccountAuth$ | async as selectedAuth">
        <div
          *ngIf="selectedAuth.linkedInAccount?.accountState == LinkedInAccountState._3"
          class="w-full min-h-140 max-w-screen-md m-auto text-center px-0 flex flex-col mb-2 content-center items-center justify-items-center">
          <!-- PIN -->
          <ng-container
            *ngIf="
              selectedAuth.linkedInAccount?.accountStateReason == LinkedInAccountStateReason._6 ||
              selectedAuth.linkedInAccount?.accountStateReason == LinkedInAccountStateReason._8
            ">
            <div class="flex flex-col">
              <p class="text-dark text-3xl text-center font-extrabold mb-6">LinkedIn PIN Code</p>
            </div>
            <p class="w-full text-center text-secondary font-medium mb-8">
              As you have logged into LinkedIn from a new device, you need to verify this account.
              Check your email
              <span class="text-dark font-semibold">{{ connectedAccountEmail }}</span>
              for the PIN code that LinkedIn sent you, and enter it below.
            </p>
          </ng-container>
          <!-- Authenticator -->
          <ng-container
            *ngIf="
              selectedAuth.linkedInAccount?.accountStateReason == LinkedInAccountStateReason._9 ||
              selectedAuth.linkedInAccount?.accountStateReason == LinkedInAccountStateReason._11
            ">
            <div class="text-dark text-3xl text-center font-extrabold mb-6 mt-3 w-full">
              Authenticator app/SMS verification
            </div>
            <p class="text-secondary">
              Since you've logged into LinkedIn from a new location, we need to verify your account.
              Please use the authenticator app or check your SMS inbox for a 6-digit verification
              code. Enter the code below to validate your login access.
            </p>
          </ng-container>
          <div class="md:flex md:flex-col sm:block px-10">
            <div class="md:flex sm:block">
              <security-code
                #securityCode
                class="text-center"
                (pinCodeChange)="pinChange($event)"
                [pinCode]="(linkedInAccountAuthsPins$ | async)?.[selectedAuth.linkedInAccountId]"
                [disabled]="isProcessingPin$ | async"
                [applyRedBorder]="
                  (accountsWithIncorrectPin$ | async)?.[selectedAuth.linkedInAccountId] === false
                "></security-code>
              <button
                heyReachButton
                buttonType="primary"
                class="min-h-12 min-w-30 sm:mt-6 md:mt-0"
                [disabled]="
                  !(
                    (linkedInAccountAuthsPins$ | async)?.[selectedAuth.linkedInAccountId]
                    | isPinValidPipe
                  ) || (isProcessingPin$ | async)
                "
                (click)="verifyPin()">
                <div class="px-4" [loading]="isProcessingPin$ | async">Submit</div>
              </button>
            </div>
            <span
              class="text-warn font-medium flex justify-start text-base mt-2 ml-6"
              *ngIf="
                (accountsWithIncorrectPin$ | async)?.[
                  (selectedAccountAuth$ | async).linkedInAccountId
                ] === false
              ">
              Invalid PIN
            </span>
          </div>
          <div
            class="w-full relative text-center mt-8"
            *ngIf="
              selectedAuth.linkedInAccount?.challengeType == AppLinkedInChallengeType.PIN &&
              resendPinError
            ">
            <span class="text-secondary font-medium">
              Looks like you have trouble with the pin
              <span
                class="text-warn font-semibold underline cursor-pointer"
                (click)="onReconnectAccount()">
                Re-connect your account now
              </span>
            </span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
