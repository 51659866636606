<ng-container *ngIf="!isExtensionConnecting">
  <div class="flex flex-col m-auto mb-5 lg:px-2 md:px-0 gap-y-8 w-full">
    <div *ngIf="!accountProfile">
      <div class="flex flex-col">
        <div class="w-full mx-auto items-center">
          <p
            class="text-center font-semibold mb-2 text-secondary mt-6"
            *ngIf="!isLoadingAccountProfile && hasExtension">
            <span class="pointer-events-none" (click)="openLoginConfirmation()">
              Connect your LinkedIn account via our Chrome extension
            </span>
          </p>
          <div *ngIf="isLoadingAccountProfile" class="flex items-center justify-center">
            <progress-spinner color="primary" [diameter]="12"></progress-spinner>
          </div>
        </div>
        <div *ngIf="!hasExtension" class="flex flex-col gap-4">
          <h3 class="text-lg font-semibold">
            Connect your LinkedIn account via our Chrome extension.
          </h3>
          <div class="items-center flex flex-col gap-4" *ngIf="!hasExtension">
            <p
              class="text-center p-3 bg-primary-muted rounded-lg flex gap-1 items-center justify-center w-full">
              <svg-icon
                [iconPath]="'assets/icons/info-icon.svg'"
                class="icon-size-5 text-primary"></svg-icon>
              <span>The Chrome extension is only available for Google Chrome</span>
            </p>
            <button
              heyReachButton
              buttonType="default"
              class="w-full"
              (click)="openExtensionInStore()">
              <div
                class="flex items-center justify-center gap-1"
                [loading]="isLoadingAccountProfile">
                <mat-icon svgIcon="custom:chrome" class="icon-size-5"></mat-icon>
                <span class="text-secondary">Install Chrome Extension</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Is user has connected the profile successfully with the extension -->
    <div *ngIf="accountProfile">
      <div>
        <img
          [src]="accountProfile.profileImage"
          class="rounded-full w-20 mx-auto mt-5 mb-5"
          [ngClass]="{
            'mt-0': _customProxyService.isUsingCustomProxy,
            'mt-5': !_customProxyService.isUsingCustomProxy
          }" />
        <div *ngIf="!(accountAlreadyConnected$ | async)">
          <div
            *ngIf="!_customProxyService.isUsingCustomProxy"
            class="text-xl text-center font-bold mt-5 mb-5">
            Do you want to connect {{ accountProfile.firstName }} to
            <br />
            {{ companyName }}?
          </div>
        </div>
      </div>
      <div
        *ngIf="_customProxyService.isUsingCustomProxy && !isAgency"
        class="flex py-2 px-3 mb-7 text-md bg-sand-muted rounded-lg text-sand"
        role="alert">
        <mat-icon
          class="icon-size-5 relative top-1 text-sand"
          svgIcon="heroicons_solid:warning-triangle"></mat-icon>
        <span class="mt-1 ml-2">
          <p class="text-sm font-medium">
            Adding your own proxy, can affect your overall {{ companyName }}
            experience. Proceed only if you know what you are doing.
          </p>
        </span>
      </div>
      <!-- If proxy is toggled -->
      <div
        class="w-full mb-8"
        *ngIf="accountProfile && (_customProxyService.isUsingCustomProxy || isAgency)">
        <div
          *ngIf="!(accountAlreadyConnected$ | async)"
          class="flex flex-col gt-xs:flex-row gt-xs:items-baseline mb-2 text-left">
          <app-proxy-select></app-proxy-select>
        </div>
      </div>
      <div *ngIf="!(accountAlreadyConnected$ | async)">
        <linkedin-inbox-configuration
          [inboxConfigurations]="inboxConfigurations"
          [label]="inboxScrapeConfigurationLabel"
          [inboxPrivacyConfiguration]="accountProfile.inboxScrapeConfiguration"
          (onSelectedInboxOptionChange)="
            selectedInboxOptionChange($event)
          "></linkedin-inbox-configuration>
        <app-country-select
          *ngIf="!isUsingCustomProxy"
          (onSelectedCountryCode)="onCountryCodeChange($event)"></app-country-select>
      </div>
      <!-- Main action -->
      <div class="flex justify-between flex-col w-full gap-y-3">
        <button
          heyReachButton
          buttonType="primary"
          [disabled]="
            accountProfile
              | isConnectAccountFormDisabledPipe
                : _customProxyService.isUsingCustomProxy
                : _customProxyService.isVerificationStatus
                : true
                : true
                : isExtensionConnecting
          "
          (click)="connectAccountViaExtension()"
          *ngIf="!(accountAlreadyConnected$ | async)">
          <div class="flex items-center">
            <span class="ml-1 text-md">Yes, connect</span>
          </div>
        </button>
        <button heyReachButton buttonType="default" (click)="changeLinkedInAccount()">
          No, change account
        </button>
      </div>
    </div>
    <button
      *ngIf="!isUsingCustomProxyMandatory && hasExtension"
      buttonType="default"
      heyReachButton
      class="w-full"
      (click)="toggleProxy.emit()">
      <div class="flex items-center">
        <span class="ml-2" *ngIf="!isUsingCustomProxy">Add your own proxy</span>
        <span class="ml-2" *ngIf="isUsingCustomProxy">
          Switch back to {{ companyName }} proxies
        </span>
      </div>
    </button>
    <div class="w-full text-center" *ngIf="accountProfile">
      or
      <a class="underline text-primary font-bold" (click)="loginWithLinkedIn()">
        login manually with credentials
      </a>
    </div>
  </div>
</ng-container>
