<mat-label class="text-secondary text-left text-base font-medium">Select your country</mat-label>
<div *ngIf="countryLoading || !countries$" class="mt-3 text-secondary">Loading country...</div>
<mat-form-field
  *ngIf="!countryLoading && countries$"
  class="w-full s-mat-dense s-mat-no-subscript mt-2 mb-3">
  <mat-select
    class="text-secondary font-bold"
    placeholder="Choose your country"
    [(ngModel)]="selectedCountryCode"
    [ngModelOptions]="{ standalone: true }"
    (ngModelChange)="onSelectedCountry()">
    <div class="sticky px-3 py-2 bg-third-background -top-2 z-10">
      <div
        class="flex flex-auto h-11 items-center rounded-lg border-2 px-4 py-2 border-pale bg-gray-50">
        <mat-icon class="icon-size-5 text-gray-300 mr-2" svgIcon="custom:search-default"></mat-icon>
        <input
          type="text"
          placeholder="Search a country"
          class="form-control w-full border-none bg-gray-50 placeholder-gray-300"
          (input)="onSearchCountry($event)" />
      </div>
    </div>

    <mat-option
      *ngFor="let option of countries$ | async"
      [value]="option.code"
      [class.hidden]="option.code === selectedCountryCode && hideSelectedCountry">
      <div class="inline-block">
        <span class="text-xl">{{ option.flag }}</span>
        &nbsp;&nbsp;&nbsp;&nbsp;{{ option.name }}
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
