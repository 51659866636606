import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { PasswordVisibilityComponent } from '@shared/components/toggle-password-visibility/password-visibility.component';
import { InboxScrapeConfiguration } from '@shared/service-proxies/service-proxies';
import { MatIconModule } from '@angular/material/icon';
import { LinkedInInboxConfigurationComponent } from '@shared/components/linkedin/inbox-configuration/inbox-configuration.component';
import { HeyReachButtonDirective } from '@shared/directives/heyreach-button.directive';
import { ConnectAccountProxyComponent } from '../connect-account-proxy/connect-account-proxy.component';
import { WhiteLabelService } from '@app/white-label.service';
import { BehaviorSubject, debounceTime, Subject, takeUntil } from 'rxjs';
import { CountrySelectComponent } from '@app/linkedin-accounts/connect-account/country-select/country-select.component';

@Component({
  selector: 'app-manual-account-2fa-setup',
  templateUrl: './manual-account-2fa-setup.component.html',
  imports: [
    FormsModule,
    PasswordVisibilityComponent,
    NgIf,
    MatIconModule,
    LinkedInInboxConfigurationComponent,
    HeyReachButtonDirective,
    ConnectAccountProxyComponent,
    CountrySelectComponent,
  ],
  standalone: true,
  host: {
    class: 'flex flex-col gap-y-2',
  },
})
export class ManualAccount2faSetupComponent implements OnDestroy {
  destroy$: Subject<void> = new Subject<void>();

  @Input() username: string;
  @Output() usernameChange = new EventEmitter<string>();

  @Input() password: string;
  @Output() passwordChange = new EventEmitter<string>();

  @Input() secretKey: string;
  @Output() secretKeyChange = new EventEmitter<string>();

  @Input() inboxScrapeConfiguration: InboxScrapeConfiguration;
  @Output() inboxConfigurationChange = new EventEmitter<InboxScrapeConfiguration>();
  @Output() countryCodeChange = new EventEmitter<string>();

  @Input() isDisabled: boolean = false;
  @Input() inboxScrapeConfigurationLabel: string;
  @Input() inboxConfigurations: { value: InboxScrapeConfiguration; text: string }[];
  @Input() isUsingCustomProxy: boolean = false;
  @Input() isUsingCustomProxyMandatory: boolean = false;
  @Input() isProxyValidated: boolean = false;

  @Output() connectAccount = new EventEmitter<void>();
  @Output() toggleProxy = new EventEmitter<void>();
  secretKeyHasError: boolean = false;

  isWhiteLabel: boolean = this._whiteLabelService.notUs;
  companyName: string = this._whiteLabelService.companyName;

  private secretKeySubject = new BehaviorSubject<string>('');

  constructor(private _whiteLabelService: WhiteLabelService) {
    this.secretKeySubject.pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe((key) => {
      this.secretKeyHasError =
        key.trim().length > 0 && (!/^[a-zA-Z0-9]+$/.test(key) || key.length != 32);
    });
  }

  get isFormDisabled(): boolean {
    const isProxyValid = this.isUsingCustomProxy ? this.isProxyValidated : true;
    const isFormValid =
      !!this.username &&
      !!this.password &&
      !!this.secretKey &&
      !this.secretKeyHasError &&
      this.inboxScrapeConfiguration !== undefined;
    return !isFormValid || !isProxyValid;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onUsernameChange(username: string): void {
    this.usernameChange.emit(username);
  }

  onPasswordChange(password: string): void {
    this.passwordChange.emit(password);
  }

  onSecretKeyChange(secretKey: string): void {
    this.secretKey = secretKey;
    this.secretKeyChange.emit(secretKey);
    this.secretKeySubject.next(secretKey);
  }

  onInboxConfigurationChange(config: InboxScrapeConfiguration): void {
    this.inboxConfigurationChange.emit(config);
  }

  onCountryCodeChange(code: string): void {
    this.countryCodeChange.emit(code);
  }

  onConnectAccount(): void {
    this.connectAccount.emit();
  }

  onToggleProxy(): void {
    this.toggleProxy.emit();
  }
}
