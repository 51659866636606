<div *ngIf="showAsButton">
  <div *ngIf="!isEmailEditMode" class="flex items-center text-left truncate">
    <!-- If there is email -->
    <a
      *ngIf="userEmail"
      [matTooltip]="userEmail"
      [matTooltipDisabled]="disableEmailTooltip"
      class="hover:text-primary text-secondary font-semibold"
      click-stop-propagation
      [href]="'mailto:' + userEmail">
      {{ userEmail }}
    </a>
    <!-- If there is no eamil searched for -->
    <button
      *ngIf="!userEmail && !emailEnrichmentAtempted"
      class="text-secondary"
      heyreachButton
      buttonType="default"
      click-stop-propagation
      (click)="enrichEmail()"
      [disabled]="enrichingInProgress">
      <div [loading]="enrichingInProgress" class="flex items-center">
        <mat-icon svgIcon="custom:find_email" class="icon-size-5"></mat-icon>
        <span class="ml-2">Find email</span>
      </div>
    </button>
    <!-- If there is no eamil found -->
    <div *ngIf="!userEmail && emailEnrichmentAtempted" class="text-danger">No Email Found</div>
    <button
      heyreachButton
      buttonType="icon"
      matTooltip="Add e-mail"
      *ngIf="enableEmailEditing"
      (click)="setEmailEditMode(true)">
      <mat-icon svgIcon="heroicons_solid:pencil-square" class="icon-size-5"></mat-icon>
    </button>
  </div>
  <!-- If we want to allow editing of the email -->
  <ng-container *ngIf="isEmailEditMode">
    <div class="flex items-center">
      <mat-form-field class="flex-1 mt-2">
        <input
          class="w-full"
          type="email"
          mat-input
          matInput
          [(ngModel)]="newEmail"
          placeholder="New e-mail" />
      </mat-form-field>
      <button
        matTooltip="Update"
        heyreachButton
        buttonType="icon"
        [disabled]="isEmailButtonDisabled"
        (click)="updateEmail()">
        <div [loading]="isUpdateEmailInProgress">
          <mat-icon
            class="icon-size-5 text-primary"
            svgIcon="heroicons_solid:pencil-square"></mat-icon>
        </div>
      </button>
      <button
        heyreachButton
        buttonType="icon"
        matTooltip="Cancel"
        (click)="setEmailEditMode(false)"
        [disabled]="isUpdateEmailInProgress">
        <mat-icon class="icon-size-5 text-danger" svgIcon="cancel"></mat-icon>
      </button>
    </div>
  </ng-container>
</div>
<div class="w-full" *ngIf="!showAsButton">
  <div *ngIf="!isEmailEditMode" class="flex items-center w-full text-left truncate gap-2">
    <mat-icon *ngIf="userEmail" svgIcon="custom:find_email" class="icon-size-4"></mat-icon>
    <!-- If there is email -->
    <a
      *ngIf="userEmail"
      [matTooltip]="userEmail"
      [matTooltipDisabled]="disableEmailTooltip"
      class="hover:text-primary text-secondary underline max-w-40 truncate"
      click-stop-propagation
      [href]="'mailto:' + userEmail">
      {{ userEmail }}
    </a>
    <!-- If there is no eamil searched for -->
    <button
      *ngIf="!userEmail && !emailEnrichmentAtempted"
      class="text-secondary border-2 border-gray-200 rounded-lg px-2 py-1"
      click-stop-propagation
      (click)="enrichEmail()"
      [disabled]="enrichingInProgress">
      <div [loading]="enrichingInProgress" class="flex items-center">
        <mat-icon svgIcon="custom:find_email" class="icon-size-5"></mat-icon>
        <span class="ml-2">Find email</span>
      </div>
    </button>
    <!-- If there is no eamil found -->
    <div *ngIf="!userEmail && emailEnrichmentAtempted" class="text-danger">No Email Found</div>
    <div class="flex-grow"></div>
    <button matTooltip="Add e-mail" *ngIf="enableEmailEditing" (click)="setEmailEditMode(true)">
      <mat-icon svgIcon="heroicons_solid:pencil-square" class="icon-size-5"></mat-icon>
    </button>
  </div>
  <!-- If we want to allow editing of the email -->
  <ng-container *ngIf="isEmailEditMode">
    <div class="flex items-center gap-3">
      <mat-form-field class="flex-1">
        <input
          class="w-full"
          type="email"
          mat-input
          matInput
          [(ngModel)]="newEmail"
          placeholder="New e-mail" />
      </mat-form-field>
      <button matTooltip="Update" [disabled]="isEmailButtonDisabled" (click)="updateEmail()">
        <div [loading]="isUpdateEmailInProgress">
          <mat-icon
            class="icon-size-5 text-primary"
            svgIcon="heroicons_solid:pencil-square"></mat-icon>
        </div>
      </button>
      <button
        matTooltip="Cancel"
        (click)="setEmailEditMode(false)"
        [disabled]="isUpdateEmailInProgress">
        <mat-icon class="icon-size-5 text-danger" svgIcon="cancel"></mat-icon>
      </button>
    </div>
  </ng-container>
</div>
