import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { shareReplay } from 'rxjs/operators';
import { LinkedInAccountServiceProxy } from '@shared/service-proxies/service-proxies';

@Injectable()
export class UserCountryService {
  readonly userCountryISO$ = fromFetch('https://www.cloudflare.com/cdn-cgi/trace', {
    selector: async (response) => response.text(),
  }).pipe(
    map((obj) => {
      const match = this.regExp.exec(obj);
      this.regExp.lastIndex = 0;
      if (match && match.length >= 2 && match[1]) {
        return match[1].toLowerCase();
      }
      return 'us';
    }),
    shareReplay(1),
  );

  readonly allowedProxyCountryCodes$ = this._liAccountService
    .getAllowedProxyCountryCodes()
    .pipe(shareReplay(1));
  private readonly regExp = /^loc=(.*)$/gm;

  constructor(private _liAccountService: LinkedInAccountServiceProxy) {}
}
