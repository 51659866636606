import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { UserCountryService } from '@shared/user-country/user-country.service';
import { map, take } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { BehaviorSubject, Observable } from 'rxjs';
import { ALL_COUNTRIES, ICountry } from '@shared/AppCountries';

@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  imports: [CommonModule, FormsModule, MatSelectModule, MatIconModule],
  standalone: true,
})
export class CountrySelectComponent implements OnChanges {
  selectedCountryCode: string = 'us';
  hideSelectedCountry: boolean = false;
  @Input() initiallySelectedCountryCode: string;
  @Input() countryLoading: boolean = false;
  @Output() onSelectedCountryCode = new EventEmitter<string>();

  private searchKeywordSubject$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private searchKeyword$ = this.searchKeywordSubject$.asObservable();
  private allCountries: ICountry[];
  countries$: Observable<ICountry[]>;

  constructor(private userCountryService: UserCountryService) {
    this.userCountryService.allowedProxyCountryCodes$
      .pipe(take(1))
      .subscribe((supportedCountryCodes) => {
        this.allCountries = ALL_COUNTRIES.filter((c) => supportedCountryCodes.includes(c.code));
        this.init();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.initiallySelectedCountryCode?.previousValue !==
        changes.initiallySelectedCountryCode?.currentValue &&
      changes.initiallySelectedCountryCode?.currentValue
    ) {
      this.initSelectedCountryCode(changes.initiallySelectedCountryCode.currentValue);
    }
  }

  onSelectedCountry(): void {
    this.onSelectedCountryCode.emit(this.selectedCountryCode);
  }

  onSearchCountry(event: Event): void {
    this.searchKeywordSubject$.next((event.target as HTMLInputElement).value);
  }

  private init() {
    if (this.initiallySelectedCountryCode) {
      this.initSelectedCountryCode(this.initiallySelectedCountryCode);
      this.initCountries();
    } else {
      this.userCountryService.userCountryISO$.pipe(take(1)).subscribe((countryCode) => {
        this.initSelectedCountryCode(countryCode);
        this.initCountries();
      });
    }
  }

  private initCountries() {
    this.countries$ = this.searchKeyword$.pipe(
      map((keyword) => {
        this.hideSelectedCountry = false;

        if (keyword.trim().length === 0) {
          return this.allCountries;
        }

        const keywordLowerCased = keyword.toLowerCase().trim();
        const filteredCountries = this.allCountries.filter((c) =>
          c.name.toLowerCase().includes(keywordLowerCased),
        );
        if (!filteredCountries.map((c) => c.code).includes(this.selectedCountryCode)) {
          const missingCountry = this.allCountries.find((c) => c.code === this.selectedCountryCode);
          if (missingCountry) {
            this.hideSelectedCountry = true;
            filteredCountries.push(missingCountry);
          }
        }

        return filteredCountries;
      }),
    );
  }

  private initSelectedCountryCode(countryCode: string) {
    this.userCountryService.allowedProxyCountryCodes$
      .pipe(take(1))
      .subscribe((supportedCountryCodes) => {
        if (supportedCountryCodes.includes(countryCode)) {
          this.selectedCountryCode = countryCode;
          this.onSelectedCountry();
        }
      });
  }
}
